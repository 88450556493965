import * as React from 'react'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import PropTypes from 'prop-types'

import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import { DepositoJudicialContext } from '../../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
import { format } from 'date-fns'

import { LegalInsightsCrudContext } from '../../../../../contexts/LegalInsights/LegalInsightsCrudContext'
import { AuthContext } from '../../../../../contexts/LegalInsights/auth'
import DownloadIcon from '@mui/icons-material/Download'
import { ConfirmDeleteToast, showToast } from '../../../../../utils/Compartilhados/CustomToast'
import { toast } from 'react-toastify'


const GridIdentificacao = ( {dataFilter} ) => {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const { getIdentificacao, atualizarIdentificacaoPorIdProcessoEValidaArquivo, identificacao } = React.useContext(DepositoJudicialContext)
    const { salvarArquivoAws, buscaArquivoPorIdProcessoENomeColuna, donwloadAzureStorageBlod } = React.useContext(LegalInsightsCrudContext)
    const { user } = React.useContext(AuthContext)
    const [ editarGrid, setEditarGrid ] = React.useState(true)
    const [ file, setFile ] = React.useState()    
    const useRef = React.useRef

    React.useEffect(()=>{
        getIdentificacao()
        if(user.tipoUsuario !== "Administrador Global"){
            setEditarGrid(false)
        }
    },[])

    React.useEffect(()=>{
        if(identificacao.length > 0){
            if(user.tipoUsuario !== "Administrador Global"){
                const _identificacao = identificacao.filter( (item)=> item.empresa === user.nomeCliente)
                setRows(_identificacao)
            }

            if(user.tipoUsuario === "Administrador Global"){
                setRows(identificacao)
            }
        }    
    },[identificacao])

    const handleRowEditStop = (params, event) => () => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })        
    }

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }

        setFile()
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false }
        setRows(prevRows => prevRows.map(row => (row.id === updatedRow.id ? updatedRow : row)))       
        await atualizarIdentificacaoPorIdProcessoEValidaArquivo(updatedRow.id, updatedRow, '')
        await getIdentificacao()
        return updatedRow

    };

    const handleRowModesModelChange = (newRowModesModel) => () => {
        setRowModesModel(newRowModesModel);
    };

    const handleValueGetterDate = (params) => {
        const value = params.row[params.field]
    
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {    
            const [day, month, year] = value.split("/").map(Number)
            
            const date = new Date(year, month - 1, day)            
            
            return isNaN(date.getTime()) ? null : date
        }

        if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
            const date = new Date(value);
            return isNaN(date.getTime()) ? null : date;
        }
        
        return null
    }

    const handleValueFormatterDate = (params) => {
        if (!params.value) return ''
        return format(new Date(params.value), 'dd/MM/yyyy')
    }

    const handleDeleteFileOnClick = async (row, nomeColuna) => {
        const idToast = toast(
            <ConfirmDeleteToast
                onConfirm={async () => {
                    row[nomeColuna] = false
                    const updateRow = row

                    await atualizarIdentificacaoPorIdProcessoEValidaArquivo(row.id, updateRow, nomeColuna)
                    await getIdentificacao()
                    toast.dismiss(idToast)
                    showToast('success', 'Arquivo deletado com sucesso!')                    
                }}
                onCancel={() => {
                    toast.dismiss(idToast)
                }}
            />,
            {
                autoClose: false,
                closeOnClick: false,
            }
        );
    }

    const handleSaveFileUpload = async (id, row) => {
        const updatedRow = { ...row, isNew: false }      
        const _file = file?.file        
        const _payloadData = file?.payloadData   
        
            if(!_file) return showToast('warning', 'Nenhum arquivo selecionado!')

            if(updatedRow){
                salvarArquivoAws(_file, _payloadData)
                updatedRow[_payloadData.nomeColunaGrid] = true   
                setRows(prevRows => prevRows.map(r => r.id === row.id ? updatedRow : r))                                
                setFile() 
                showToast('success', 'Arquivo salvo com sucesso!')
            }     
            setRowModesModel(prevModel => ({ ...prevModel, [id]: { mode: GridRowModes.View } }))
          return updatedRow

    }

    const download = async ( row, nomeColuna) => {
        const arquivo = await buscaArquivoPorIdProcessoENomeColuna(row.processo, nomeColuna)
        const { accountName, containerName, blobName } = arquivo.azureStorageBlod
     
        
        const linkDownload = await donwloadAzureStorageBlod(accountName, containerName, blobName)       
        const a = document.createElement('a');
        a.href = linkDownload;
        a.download = ''; 
        a.rel = 'noopener noreferrer';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const formataDinheiro = (params) => {
            if (params.value != null) {
              return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params.value);
            }
            return params.value;
    }


    const columns = [
    { field: "id", headerName: "ID", hide: true, headerAlign: 'center' },

    {
        field: 'actions',
        type: 'actions',
        headerName: 'Editar registro',
        width: 150,
        cellClassName: 'actions',
        getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
            return [
            <GridActionsCellItem
                key={id}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                    color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
                key={id}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
            />,
            ];
        }

        return [
            <GridActionsCellItem
                key={id}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
            />,
        ]
        },
    },
    { field: 'empresa', headerName: "Empresa", headerAlign: 'center', align: 'center',  width: 200 },    
    { field: "descrLinha", headerName: "Descr Linha",  headerAlign: 'center', align: 'center',  width: 350 },
    
    { field: "dtgl", headerName: "DT GL", headerAlign: 'center', align: 'center',  width: 100,  type: 'date',
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,
    },
    { field: "periodo", headerName: "Período", headerAlign: 'center', align: 'center',  width: 100 , type: 'date',
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate,},
    { field: "dtCriacao", headerName: "DT Criação", headerAlign: 'center', align: 'center',  width: 100, type: 'date',
        valueGetter: handleValueGetterDate,
        valueFormatter: handleValueFormatterDate, 
    },
    { field: "saldoR", headerName: "Saldo R", headerAlign: 'center', align: 'center',  width: 100,
        valueFormatter: formataDinheiro 
    },
    { field: "processo", headerName: "Processo", headerAlign: 'center', align: 'center',  width: 250,  editable: editarGrid },
    { field: "mesRefPag", headerName: "Mês ref pag", headerAlign: 'center', align: 'center',  width: 100 },
    { field: "feeLegal", headerName: "Fee legal", headerAlign: 'center', align: 'center',  width: 100, 
        valueFormatter: formataDinheiro  
    },
    { field: "controlePagamento", headerName: "Controle Pagamento", headerAlign: 'center', align: 'center',  width: 300, editable: editarGrid  },
     
    { field: "linkComprovante", headerName: "Link comprovante", headerAlign: 'center', align: 'center', width: 150, type: 'actions',
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const fileInputRef = useRef(null)    
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            const row = identificacao.filter(( item )=> item.id === id)[0]
            
            const handleIconClickUpload = () => {        
                fileInputRef.current.click()
            }

            const handleFileChange = (event) => {
                const file = event.target.files[0]
                const processo = row.processo
                const empresa = row.empresa
                const colunaGrid = "linkComprovante"
      
                if (file) {
                  const payloadData = {
                      nomeUsuario: user.nome,
                      baseCliente: empresa,
                      tipoArquivo: file.name,
                      modulo: "Deposito Judicial",
                      processo: processo,
                      nomeColunaGrid: colunaGrid
                  }
      
                  setFile({ file: file, payloadData: payloadData })
                  
                return showToast('success','Upload realizado com sucesso!')          
                  
                }
            }

            
            if (isInEditMode) {
                if(row.linkComprovante){
                    return [
                        <GridActionsCellItem
                            key={'2'}
                            icon={<DownloadIcon sx={{ color: '#4e1975' }}/>}
                            label="Download"
                            className="textPrimary"
                            onClick={()=> download(row, "linkComprovante")}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            key={'3'}
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={ ()=> handleDeleteFileOnClick(row,'linkComprovante')}
                            color="inherit"
                        />,        
                        <GridActionsCellItem
                            key={'4'}
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ]
                }                
                if(!row.linkComprovante){
                    return [
                        <GridActionsCellItem
                            key={'1'}
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={ () => handleSaveFileUpload(id, row)}
                        />,
                        <GridActionsCellItem
                            key={'2'}
                            icon={<CloudUploadIcon sx={{ color: file? '#4e1975' : ''}}/>}
                            label="Upload"
                            className="textPrimary"
                            onClick={(event)=> handleIconClickUpload(event, row)}
                            color="inherit"
                        />,
                        <input
                            key={'3'}
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={(event)=>handleFileChange(event)}
                        />,
                        <GridActionsCellItem
                            key={'4'}
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ]
                }               
            }

            if(isInEditMode === false && row.linkComprovante){
                return [
                    <GridActionsCellItem
                        key={'2'}
                        icon={<DownloadIcon sx={{ color: '#4e1975' }}/>}
                        label="Download"
                        className="textPrimary"
                        onClick={()=> download( row, "linkComprovante")}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        key={'3'}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={ ()=> handleDeleteFileOnClick(row,'linkComprovante')}
                        color="inherit"
                    />
                ]
            }
    
            return [
                <GridActionsCellItem
                    key={'1'}
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />,
                ]
        },
    },
    ]

  return (
    <Box
      sx={{
        height: '80vh',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={dataFilter.length > 0 ? dataFilter : rows }
        columns={columns}
        columnVisibilityModel={{
            id: false,
            actions: editarGrid
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
}


GridIdentificacao.propTypes ={
    dataFilter: PropTypes.array
}


export default GridIdentificacao